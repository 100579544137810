/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import TeamCard from "components/TeamCard"

// Images



import img_0 from "assets/images/students/0.jpg" //김수환
import img_1 from "assets/images/students/1.jpg" //김명수
import img_2 from "assets/images/students/2.jpg" //마이카
import img_3 from "assets/images/students/3.jpg" //강성민
import img_4 from "assets/images/students/4.jpg" //최민규
import img_5 from "assets/images/students/5.jpg" //황준용




import axios from 'axios'
import {useEffect, useState} from 'react'

// status | start_date | end_date (if not null, isAlumni) | email | topic

const reqUrlPrefix = process.env.REACT_APP_ENV === "development" ? `http://localhost:5000` : "https://api.netsys.skku.edu";
// const reqUrlPrefix = "https://api.netsys.skku.edu/content/student/"

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const dateFormatting = (student) => {
    const {start_year, start_month, end_year, end_month} = student;
    let start_date = '';
    let end_date = '';

    if (start_year && start_month){
      start_date = monthNames[start_month-1] + ". '" + String(start_year).slice(2);
    }

    if (end_year && end_month){
      end_date = monthNames[end_month-1] + ". '" + String(end_year).slice(2);
    }

    if (!start_date && !end_date)
      return ""

    return start_date + "~" + end_date
  }

  const colorDegreeMatch = (degree) => {
    switch (degree) {
      case "phd":
      case "combined":
        return "error";
      case "master":
        return "info";
      case "under":
        return "success";
      default:
        return "default";
    }
  }

  const degreeFormatting = (degree) => {
    switch (degree) {
      case "phd":
        return "Ph.D";
      case "combined":
        return "Combined M.S./Ph.D";
      case "master":
        return "M.S.";
      case "under":
        return "B.S.";
      default:
        return "phd/combined/master/under";
    }
  }

function Team() {

  const [data, setData] = useState([]);
  const student_img = [img_0, img_1, img_2, img_3, img_4, img_5]; // 사진 맨 위에 import 후 여기 추가ㅣ

  useEffect(async() => {
    /*const result = await axios({
      method: "get",
      url: `${reqUrlPrefix}/student`,
      responseType: "json",
      // params: { something: "asd", asdf: "as"}
    });*/
    
    const student_data = [
      {
        "key" : "0",
        "_id" : "0",
        "name" : "SuHwan Kim",
        "degree" : "phd",
        "is_alumni" :false,
        "start_year" : 2023,
        "start_month" : 3,
        "end_year" : 2025,
        "end_month" : 9,
        "topic" : "GPU, System network",
        "email" : "tnghks9735@gmail.com",
        "image_type" : "jpg",
        "created" : new Date()
      },
      {
        "key" : "1",
        "_id" : "1",
        "name" : "MyeongSu Kim",
        "degree" : "combined",
        "is_alumni" : false,
        "start_year" : 2023,
        "start_month" : 9,
        "end_year" : 0,
        "end_month" : 0,
        "topic" : "GPU-Sharing",
        "email" : "kimmstop@gmail.com",
        "image_type" : "jpg",
        "created" : new Date()
      },
      {
        "key" : "2",
        "_id" : "2",
        "name": "Maike Helbig",
        "degree": "master",
        "is_alumni": false,
        "start_year": 2020,
        "start_month": 12,
        "end_year": 2025,
        "end_month": 8,
        "topic": "Kernel Network Stack, eBPF",
        "email":"hema@g.skku.edu",
        "image_type" : "jpg",
        "created" : new Date()
      },
      {
        "key" : "3",
        "_id" : "3",
        "name" : "SungMin Kang",
        "degree" : "master",
        "is_alumni" : true,
        "start_year" : 2021,
        "start_month" : 3,
        "end_year" : 2024,
        "end_month" : 2,
        "topic" : "SR-IOV",
        "email" : "ksmin1114@g.skku.edu",
        "image_type" : "jpg",
        "created" : new Date()
      },
      {
        "key" : "4",
        "_id" : "4",
        "name": "MinGyu Choi",
        "degree" : "master",
        "is_alumni" : true,
        "start_year" : 2021,
        "start_month" : 9,
        "end_year" : 2023,
        "end_month" : 2,
        "topic" : "RDMA, Multi-path",
        "email" : "mingyuchoi514@gmail.com",
        "image_type" : "jpg",
        "created" : new Date()
      },
      {
      "key" : "5",
      "_id" : "5",
      "name" : "JoonYong Hwang",
      "degree" : "master",
      "is_alumni" : false,
      "start_year" : 2022,
      "start_month" : 3,
      "end_year" : 2025,
      "end_month" : 9,
      "topic" : "NVMe-OF, SR-IOV",
      "email" : "brian11hwangb@gmail.com",
      "image_type" : "jpg",
      "created" : new Date()
      },
    ];
    setData(student_data);
    console.log(student_data);
  }, [])
  
  let students, phd=[], master=[], under=[], alumni=[];
  // Request Student Data
  if (data){
    
    let students = data;
    for (var student of students){
      if (student.is_alumni === true)
        alumni.push(student);
      else{
       
        console.log(student);
        
        switch (student.degree) {
          case 'phd':
          case 'combined':
            phd.push(student);
            break;
          case 'master':
            master.push(student);
            break;
          case 'under':
            under.push(student);
            break;
          default:
            console.log("Wrong Classification! -->", student.degree);
        }
      }
    }
  }

  return (
    <>
      {phd.length > 0 &&
      <MKBox
      component="section"
      variant="gradient"
      bgColor="white"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="inherit">
              Ph.D. Students
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
        {
          phd.map(student => {
            return (
              <Grid key={student._id} item xs={12} lg={6}>
              <MKBox key={student._id} mb={1}>
              <TeamCard
                key={student._id}
                image={student_img[parseInt(student.key)]}
                name={student.name}
                position={{ color: "error", status: degreeFormatting(student.degree), date: dateFormatting(student) }}
                email={student.email}
                topic={student.topic}
              />
              </MKBox>
              </Grid>
            )
          })
        }
        </Grid>
        </Container>
        </MKBox> 
      }

      
    {master.length > 0 &&
      <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              M.S. Students
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
        {
          master.map(student => {
            return (
              <Grid key={student._id} item xs={12} lg={6}>
              <MKBox key={student._id} mb={1}>
              <TeamCard
                key={student._id}
                image={student_img[parseInt(student.key)]}
                name={student.name}
                position={{ color: colorDegreeMatch(student.degree), status: degreeFormatting(student.degree), date: dateFormatting(student) }}
                email={student.email}
                topic={student.topic}
              />
              </MKBox>
              </Grid>
            )
          })
        }
        </Grid>
        </Container>
        </MKBox> 
      }

    {under.length > 0 &&
      <MKBox
      component="section"
      variant="gradient"
      bgColor="white"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="inherit">
              Undergraduate
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
        {
          under.map(student => {
            return (
              <Grid key={student._id} item xs={12} lg={6}>
              <MKBox key={student._id} mb={1}>
              <TeamCard
                key={student._id}
                image={student_img[parseInt(student.key)]}
                name={student.name}
                position={{ color: colorDegreeMatch(student.degree), status: degreeFormatting(student.degree), date: dateFormatting(student) }}
                email={student.email}
                topic={student.topic}
              />
              </MKBox>
              </Grid>
            )
          })
        }
        </Grid>
        </Container>
        </MKBox> 
      }


    {alumni.length > 0 &&
      <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Alumni
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
        {
          alumni.map(student => {
            return (
              <Grid key={student._id} item xs={12} lg={6}>
              <MKBox key={student._id} mb={1}>
              <TeamCard
                key={student._id}
                image={student_img[parseInt(student.key)]}
                name={student.name}
                position={{ color: colorDegreeMatch(student.degree), status: degreeFormatting(student.degree), date: dateFormatting(student) }}
                email={student.email}
                topic={student.topic}
              />
              </MKBox>
              </Grid>
            )
          })
        }
        </Grid>
        </Container>
        </MKBox> 
      }
    </>
  );
}

export default Team;
